import { useState, useEffect, useMemo } from "react";
import DataTable from "react-data-table-component-footer";
import Select from "react-select";

import {
  useUpdateStockMutation,
  useLazyMatchingCardStockQuery,
  useLazyGetMatchingCardStockByKeywordQuery,
  useLazyMatchingCardStockCountQuery,
} from "../../servicesRtkQuery/publicApi";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import TextBox from "../../components/form/TextBox";
import CountUp from "react-countup";
import DataTableLoader from "../../components/page/DataTableLoader";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import { TableAction } from "../../components/modal/TableAction";
import { useLocation, useNavigate } from "react-router-dom";
import MatchingStockPreview from "../../components/pdf/stock/MatchingCardStockPreview";

export default function MatchingCardStocks() {
  const [trigger, result] = useLazyMatchingCardStockQuery();
  const { isSuccess, isFetching, isError, error } = result;
  const user: any = localStorage.getItem("user");
  const userDetails = JSON.parse(user);
  // count Query
  const [countTrigger, countResult] = useLazyMatchingCardStockCountQuery();
  const { isSuccess: isCount, isFetching: isNotCount } = countResult;
  const [cardTrigger, cardResult] = useLazyGetMatchingCardStockByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;
  // console.log("cardResult",cardResult?.data?.data?.TTPPM)
  const navigate = useNavigate();
  const [cardData, setCardData]: any = useState<any>([]);
  const [updateCard] = useUpdateStockMutation();
  // Filtering states
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  //Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const urlData: any = useLocation().search;
  const dashboardType = new URLSearchParams(urlData).get("type");
  const [type, setType]: any = useState(dashboardType ? dashboardType : "all");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [count, setCount]: any = useState({});
  const [selectedRow, setSelectedRow]: any = useState("");

  //pdf
  const [isExportData, setIsExportData]: any = useState(false);
  const [isPartyPdf, setIsPartyPdf]: any = useState(false);
  const [search, setSearch]: any = useState({
    date: {
      text: "",
      status: false,
    },
    receiveDate: {
      text: "",
      status: false,
    },
    day: {
      text: "",
      status: false,
    },
    type: {
      text: "",
      status: false,
    },
    invoiceNo: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    cardNo: {
      text: "",
      status: false,
    },
    clotheName: {
      text: "",
      status: false,
    },
    CM: {
      text: "",
      status: false,
    },
    height: {
      text: "",
      status: false,
    },
    TPPM: {
      text: "",
      status: false,
    },
    challanNo: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    fromreceiveDate: {
      text: "",
      status: false,
    },
    fromDateall: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
    toreceiveDate: {
      text: "",
      status: false,
    },
    toDateall: {
      text: "",
      status: false,
    },
  });
  const [selectedType, setSelectedType]: any = useState({
    type: [
      {
        label: "plain",
        value: "plan",
      },
      {
        label: "Production",
        value: "production",
      },
      {
        label: "Checking",
        value: "checking",
      },
      {
        label: "Hand",
        value: "hand",
      },
      {
        label: "Delivery",
        value: "delivery",
      },
    ],
    selectedDateType: [],
  });

  //#region API triggers
  useEffect(() => {
    if (search?.fromreceiveDate === "" && search?.toreceiveDate === "") {
      trigger(
        type === "all"
          ? { page: page, limit: perPage }
          : { page: page, limit: perPage, stockStatus: type }
      );
    }
    countTrigger({});
  }, [trigger, page, perPage, countTrigger, type]);
  useEffect(() => {
    if (selectedType?.selectedDateType?.length !== 0) {
      // Extract values from selectedDateType and create an array called types
      const types =
        selectedType?.selectedDateType?.map((item: any) => {
          return item?.value;
        }) ?? [];

      const searchKeys = Object.keys(search)
        .filter((key) => search[key].text !== "")
        .map((key) => ({ key, text: search[key].text }));

      const searchParams = searchKeys.reduce(
        (acc: Record<string, string>, { key, text }) => {
          acc[key] = text;
          return acc;
        },
        {}
      );
      // Call the cardTrigger function with a specific configuration based on the types array and all active search keys
      cardTrigger(
        types.length === 0
          ? { page: page, limit: perPage }
          : {
              page: page,
              limit: perPage,
              stockStatus: types,
              ...searchParams,
            }
      );
    }
  }, [selectedType?.selectedDateType, selectedType]);
  useEffect(() => {
    if (isCount && !isNotCount) {
      setCount(countResult?.data?.data);
    }
  }, [isCount, isNotCount]);
  useEffect(() => {
    if (isSuccess && !isFetching) {
      const updateData =
        result?.data?.data?.matchingCardStock?.matchingCardStock?.filter(
          (item: any) => item?.stockStatus === type
        );
      if (updateData && updateData.length > 0) {
        setCardData(updateData);
        setTotalRows(result?.data?.data?.matchingCardStock?.count);
      }
    } else {
      setCardData([]);
      setTotalRows(0);
    }
  }, [type, isSuccess, isFetching, result, isError, error]);

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(
        cardResult?.data?.data?.matchingCardStock
          ? cardResult?.data?.data?.matchingCardStock
          : []
      );
      setTotalRows(cardResult?.data?.data?.count);
    } else {
      setCardData([]);
      setTotalRows(0);
    }
  }, [isCardSuccess, isCardFetching]);
  const searchComponent = (key: string, label: string) => {
    return (
      <div className={`text-left`}>
        {search[key]?.status ? (
          <TextBox
            type={"text"}
            placeholder={label}
            autoFocus
            className="font-light"
            value={search[key].text}
            onChange={(e: any) => {
              if (e.target.value.length === 0) {
                setSearch({
                  ...search,
                  [key]: {
                    text: "",
                    status: false,
                  },
                });
                setCardData(cardResult?.data?.data?.matchingCardStock);
                setTotalRows(cardResult?.data?.data?.count);
              } else {
                setSearch({
                  ...search,
                  [key]: {
                    text: e.target.value,
                    status: true,
                  },
                });
              }
            }}
          />
        ) : (
          <div className="mb-2 flex flex-col">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
            </div>
            {search[key]?.text !== "" && search[key]?.text && (
              <div className="flex justify-center items-center m-1 font-medium py-1 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                    }}
                  ></i>
                </div>
                <div className="text-xs font-normal leading-none max-w-full flex-initial pr-4">
                  {search[key]?.text}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  const columns: any = [
    {
      name: "Rec. Date",
      selector: (row: any) =>
        row?.receiveDate ? moment(row?.receiveDate).format("DD-MM-yyyy") : "-",
      sortable: true,
    },
    {
      name: searchComponent("day", "Day"),
      selector: (row: any) =>
        row?.stockStatus === "delivery"
          ? row?.mday
            ? row?.mday
            : "-"
          : row?.day
          ? row?.day
          : "-",
    },
    {
      name: "Stock Status",
      selector: (row: any) => row?.stockStatus,
    },
    {
      name: searchComponent("type", "Type"),
      selector: (row: any) =>
        row?.matchingCard?.type ? row?.matchingCard?.type : "",
    },
    {
      name: searchComponent("invoiceNo", "Card No"),
      selector: (row: any) =>
        row?.matchingCard?.invoiceNo ? row?.matchingCard?.invoiceNo : "-",
    },
    {
      name: searchComponent("partyName", "Party Name"),
      selector: (row: any) =>
        row?.matchingCard?.party?.name ? row?.matchingCard?.party?.name : "-",
    },

    {
      name: searchComponent("designNo", "D.No"),
      selector: (row: any) =>
        row?.matchingCard?.designNo ? row?.matchingCard?.designNo : "",
    },
    {
      name: searchComponent("challanNo", "Ch.No"),
      selector: (row: any) =>
        row?.matchingCard?.challanNo ? row?.matchingCard?.challanNo : "",
    },
    {
      name: searchComponent("clotheName", "Fabrics"),
      selector: (row: any) =>
        row?.matchingCard?.clothe?.name ? row?.matchingCard?.clothe?.name : "",
    },
    {
      name: searchComponent("CM", "Cut"),
      selector: (row: any) =>
        row?.matchingCard?.CM ? row?.matchingCard?.CM : "",
    },
    {
      name: searchComponent("height", "Height"),
      selector: (row: any) =>
        row?.matchingCard?.height ? row?.matchingCard?.height : "",
    },

    {
      name: searchComponent("TPPM", "Total Pcs"),
      selector: (row: any) =>
        row?.matchingCard?.TPPM ? row?.matchingCard?.TPPM : "",
    },
  ];
  const columns1: any = [
    {
      name: "Rec. Date",
      selector: (row: any) =>
        row?.receiveDate ? moment(row?.receiveDate).format("DD-MM-yyyy") : "-",
      sortable: true,
    },

    {
      name: searchComponent("day", "Day"),
      selector: (row: any) =>
        row?.stockStatus === "delivery"
          ? row?.mday
            ? row?.mday
            : "-"
          : row?.day
          ? row?.day
          : "-",
    },
    {
      name: searchComponent("type", "Type"),
      selector: (row: any) =>
        row?.matchingCard?.type ? row?.matchingCard?.type : "",
    },
    {
      name: searchComponent("invoiceNo", "Card No"),
      selector: (row: any) =>
        row?.matchingCard?.invoiceNo ? row?.matchingCard?.invoiceNo : "-",
    },
    {
      name: searchComponent("partyName", "Party Name"),
      selector: (row: any) =>
        row?.matchingCard?.party?.name ? row?.matchingCard?.party?.name : "-",
    },

    {
      name: searchComponent("designNo", "D.No"),
      selector: (row: any) =>
        row?.matchingCard?.designNo ? row?.matchingCard?.designNo : "",
    },
    {
      name: searchComponent("challanNo", "Ch.No"),
      selector: (row: any) =>
        row?.matchingCard?.challanNo ? row?.matchingCard?.challanNo : "",
    },
    {
      name: searchComponent("clotheName", "Fabrics"),
      selector: (row: any) =>
        row?.matchingCard?.clothe?.name ? row?.matchingCard?.clothe?.name : "",
    },
    {
      name: searchComponent("CM", "Cut"),
      selector: (row: any) =>
        row?.matchingCard?.CM ? row?.matchingCard?.CM : "",
    },
    {
      name: searchComponent("height", "Height"),
      selector: (row: any) =>
        row?.matchingCard?.height ? row?.matchingCard?.height : "",
    },

    {
      name: searchComponent("TPPM", "Total Pcs"),
      selector: (row: any) =>
        row?.matchingCard?.TPPM ? row?.matchingCard?.TPPM : "",
    },
  ];
  //#endregion
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <div className="flex flex-wrap gap-2 items-center">
        <button
          className="w-full md:w-max justify-start"
          onClick={() => {
            setResetPaginationToggle(!resetPaginationToggle);
            setSearch({
              date: {
                text: "",
                status: false,
              },
              receiveDate: {
                text: "",
                status: false,
              },
              day: {
                text: "",
                status: false,
              },
              type: {
                text: "",
                status: false,
              },
              invoiceNo: {
                text: "",
                status: false,
              },
              partyName: {
                text: "",
                status: false,
              },
              designNo: {
                text: "",
                status: false,
              },
              cardNo: {
                text: "",
                status: false,
              },
              clotheName: {
                text: "",
                status: false,
              },
              CM: {
                text: "",
                status: false,
              },
              height: {
                text: "",
                status: false,
              },
              TPPM: {
                text: "",
                status: false,
              },
              challanNo: {
                text: "",
                status: false,
              },
              fromDate: {
                text: "",
                status: false,
              },
              fromreceiveDate: {
                text: "",
                status: false,
              },
              fromDateall: {
                text: "",
                status: false,
              },
              toDate: {
                text: "",
                status: false,
              },
              toreceiveDate: {
                text: "",
                status: false,
              },
              toDateall: {
                text: "",
                status: false,
              },
            });
            setSelectedType((prev: any) => {
              return {
                ...prev,
                selectedDateType: [],
              };
            });
            handleClear();
          }}
        >
          <i className="fas fa-filter" title="Clear Filter"></i>
        </button>
        {type === "all" && (
          <div className="w-full md:w-max">
            <Select
              name="fromreceiveDate"
              classNamePrefix="customSelect"
              className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
              isMulti={true} // Set isMulti to true for multi-selector
              onChange={(selectedOptions: any) => {
                // If selectedOptions is not null or undefined
                if (selectedOptions) {
                  // Map selectedOptions to get label and value and update selectedType
                  setSelectedType({
                    ...selectedType,
                    selectedDateType: selectedOptions.map((option: any) => ({
                      label: option.label,
                      value: option.value,
                    })),
                  });
                  if (selectedOptions?.length === 0) {
                    const searchKeys = Object.keys(search)
                      .filter((key) => search[key].text !== "")
                      .map((key) => ({ key, text: search[key].text }));

                    const searchParams = searchKeys.reduce(
                      (acc: Record<string, string>, { key, text }) => {
                        acc[key] = text;
                        return acc;
                      },
                      {}
                    );
                    cardTrigger({ page: page, limit: perPage,...searchParams });
                  }
                }
              }}
              value={selectedType?.selectedDateType || []} // Ensure value is an array
              options={selectedType?.type}
            />
          </div>
        )}
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className="fas fa-search w-4 fill-current"></i>
            </span>
            <input
              type="search"
              name="leadingIcon"
              id="leadingIcon"
              placeholder="Search here"
              value={filterText}
              onChange={(e: any) => {
                setFilterText(e.target.value);
              }}
              className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
            />
          </div>
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              setIsSearching(true);
              setSearch((previous: any) => {
                return {
                  ...previous,
                  fromreceiveDate: {
                    text: e.target.value,
                    status: true,
                  },
                };
              });
            }}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              setIsSearching(true);
              setSearch((previous: any) => {
                return {
                  ...previous,
                  toreceiveDate: {
                    text: e.target.value,
                    status: true,
                  },
                };
              });
            }}
          />
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
              setIsPartyPdf(true);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Party Pdf</span>
          </button>
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
              setIsPartyPdf(false);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Export Pdf</span>
          </button>
        </div>
      </div>
    );
  }, [resetPaginationToggle, selectedType, filterText]);

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let msId = row._id;
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            msId: msId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Card has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
          }
        });
      }
    });
  };

  //#region Pagination section
  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage, type);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage, type);
  };
  //#endregion
  const [isSearching, setIsSearching]: any = useState(false);

  const globalTriggers = (page: Number, perPage: Number, type: any) => {
    if (isSearching) {
      let searchData: any =
        type === "all"
          ? { page: page, limit: perPage }
          : {
              page: page,
              limit: perPage,
              stockStatus: type,
            };
      Object.keys(search).map((key: any) => {
        if (search[key] && search[key].text !== "") {
          searchData[key] = search[key].text;
        }
      });
      if (filterText === "" || !filterText) {
        searchData =
          type === "all"
            ? { ...searchData, type: searchData?.type }
            : {
                ...searchData,
                type: searchData?.type,
                stockStatus: type,
              };
      } else {
        searchData =
          type === "all"
            ? { type: searchData?.type, search: filterText }
            : {
                stockStatus: type,
                type: searchData?.type,
                search: filterText,
              };
      }
      if (Object.keys(searchData).length != 0) {
        cardTrigger(searchData);
      }
    } else {
      trigger(
        type === "all"
          ? { page: page, limit: perPage }
          : {
              page: page,
              limit: perPage,
              stockStatus: type,
            }
      );
    }
  };
  useEffect(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    globalTriggers(page, perPage, type);
  }, [type]);
  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]?.["status"],
      },
    });
  };
  useEffect(() => {
    console.log("called");
    let searchData: any = {};
    Object.keys(search).map((key: any) => {
      if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
    });
    const newSelectedType = selectedType?.selectedDateType
      ?.map((dateType: { value: string }) => dateType.value)
      .join(",");
    if (filterText === "" || !filterText) {
      searchData =
        type === "all"
          ? {
              ...searchData,
              type: searchData?.type,
              ...(newSelectedType !== ""
                ? { stockStatus: newSelectedType }
                : {}),
            }
          : {
              ...searchData,
              stockStatus: type,
              type: searchData?.type,
            };
    } else {
      searchData =
        type === "all"
          ? {
              type: searchData?.type,
              search: filterText,
              ...(newSelectedType !== ""
                ? { stockStatus: newSelectedType }
                : {}),
            }
          : {
              stockStatus: type,
              type: searchData?.type,
              search: filterText,
            };
    }
    if (Object.keys(searchData).length != 0) {
      setIsSearching(true);
      cardTrigger(searchData);
    } else {
      setIsSearching(false);

      globalTriggers(page, perPage, type);
    }
  }, [search, filterText]);
  useEffect(() => {
    if (userDetails?.type !== "all") {
      setType(userDetails?.type);
    }
  }, [userDetails]);
  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/6 cursor-pointer"
              onClick={() => {
                setType("all");
                navigate("/matchingcarddashboardstock");
                setFilterText("");
                setSearch((previos: any) => {
                  return {
                    ...previos,
                  };
                });
                setSelectedType((previous: any) => {
                  return {
                    ...previous,
                  };
                });
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "all"
                    ? "bg-gradient-to-r from-blue-100 to-violet-100"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                    All Stock
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp
                            end={
                              count?.plan +
                              count?.production +
                              count?.checking +
                              count?.hand +
                              count?.delivery
                            }
                            duration={0.5}
                          />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      {type === "all" ? (
                        <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl text-[8px] from-blue-500 to-violet-500">
                          <span className="leading-none text-[10px] relative top-3 text-white font-semibold">
                            <CountUp
                              end={cardResult?.data?.data?.TTPPM}
                              duration={0.5}
                            />
                          </span>
                        </div>
                      ) : (
                        <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                          <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {userDetails?.type === "all" && (
              <div
                className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/6 cursor-pointer"
                onClick={() => {
                  setType("plan");
                  navigate("/matchingcarddashboardstock");
                  setFilterText("");
                  setSearch((previos: any) => {
                    return {
                      ...previos,
                    };
                  });
                  setSelectedType((previous: any) => {
                    return {
                      ...previous,
                      selectedDateType: [],
                    };
                  });
                }}
              >
                <div
                  className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                    type === "plan"
                      ? "bg-gradient-to-r from-blue-100 to-violet-100"
                      : "bg-white"
                  } `}
                >
                  <div className="flex-auto p-4">
                    <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                      plain
                    </p>
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <h5 className="mb-2 font-bold">
                            <CountUp end={count?.plan} duration={0.5} />
                          </h5>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        {type === "plan" ? (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                            <span className="leading-none text-[12px] relative top-2.5 text-white font-semibold">
                              <CountUp
                                end={cardResult?.data?.data?.TTPPM}
                                duration={0.5}
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                            <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(userDetails?.type === "production" ||
              userDetails?.type === "all") && (
              <div
                className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/6 cursor-pointer"
                onClick={() => {
                  setType("production");
                  navigate("/matchingcarddashboardstock?type=production");
                  setFilterText("");
                  setSearch((previos: any) => {
                    return {
                      ...previos,
                    };
                  });
                  setSelectedType((previous: any) => {
                    return {
                      ...previous,
                      selectedDateType: [],
                    };
                  });
                }}
              >
                <div
                  className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                    type === "production"
                      ? "bg-gradient-to-r from-yellow-100 to-red-100"
                      : "bg-white"
                  } `}
                >
                  <div className="flex-auto p-4">
                    <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                      Production
                    </p>
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <h5 className="mb-2 font-bold">
                            <CountUp end={count?.production} duration={0.5} />
                          </h5>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        {type === "production" ? (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                            <span className="leading-none text-[12px] relative top-2.5 text-white font-semibold">
                              <CountUp
                                end={cardResult?.data?.data?.TTPPM}
                                duration={0.5}
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                            <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(userDetails?.type === "checking" ||
              userDetails?.type === "all") && (
              <div
                className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/6 cursor-pointer"
                onClick={() => {
                  setType("checking");
                  navigate("/matchingcarddashboardstock?type=checking");
                  setFilterText("");
                  setSearch((previos: any) => {
                    return {
                      ...previos,
                    };
                  });
                  setSelectedType((previous: any) => {
                    return {
                      ...previous,
                      selectedDateType: [],
                    };
                  });
                }}
              >
                <div
                  className={`relative flex mb-5 flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                    type === "checking"
                      ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                      : "bg-white"
                  } `}
                >
                  <div className="flex-auto p-4 ">
                    <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                      Checking
                    </p>
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <h5 className="mb-2 font-bold">
                            <CountUp end={count?.checking} duration={0.5} />
                          </h5>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        {type === "checking" ? (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                            <span className="leading-none text-[12px] relative top-2.5 text-white font-semibold">
                              <CountUp
                                end={cardResult?.data?.data?.TTPPM}
                                duration={0.5}
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                            <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(userDetails?.type === "hand" || userDetails?.type === "all") && (
              <div
                className="w-full   max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/6 cursor-pointer"
                onClick={() => {
                  setType("hand");
                  navigate("/matchingcarddashboardstock?type=hand");
                  setFilterText("");
                  setSearch((previos: any) => {
                    return {
                      ...previos,
                    };
                  });
                  setSelectedType((previous: any) => {
                    return {
                      ...previous,
                      selectedDateType: [],
                    };
                  });
                }}
              >
                <div
                  className={`relative  mb-5 flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                    type === "hand"
                      ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                      : "bg-white"
                  } `}
                >
                  <div className="flex-auto p-4">
                    <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                      Hand
                    </p>
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <h5 className="mb-2 font-bold">
                            <CountUp end={count?.hand} duration={0.5} />
                          </h5>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        {type === "hand" ? (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                            <span className="leading-none text-[12px] relative top-2.5 text-white font-semibold">
                              <CountUp
                                end={cardResult?.data?.data?.TTPPM}
                                duration={0.5}
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                            <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {(userDetails?.type === "Delivery" ||
              userDetails?.type === "all") && (
              <div
                className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/6 cursor-pointer"
                onClick={() => {
                  setType("delivery");
                  navigate("/matchingcarddashboardstock?type=delivery");
                  setFilterText("");
                  setSearch((previos: any) => {
                    return {
                      ...previos,
                    };
                  });
                  setSelectedType((previous: any) => {
                    return {
                      ...previous,
                      selectedDateType: [],
                    };
                  });
                }}
              >
                <div
                  className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                    type === "delivery"
                      ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                      : "bg-white"
                  } `}
                >
                  <div className="flex-auto p-4">
                    <p className="mb-0 font-sans text-xs font-semibold leading-normal capitalize">
                      Delivery
                    </p>
                    <div className="flex flex-row -mx-3">
                      <div className="flex-none w-2/3 max-w-full px-3">
                        <div>
                          <h5 className="mb-2 font-bold">
                            <CountUp end={count?.delivery} duration={0.5} />
                          </h5>
                        </div>
                      </div>
                      <div className="px-3 text-right basis-1/3">
                        {type === "delivery" ? (
                          <div className="inline-block w-12 h-12 text-center text-[8px] rounded-circle bg-gradient-to-tl from-orange-500  to-yellow-500">
                            <span className="leading-none text-[10px] relative top-3 text-white font-semibold">
                              <CountUp
                                end={cardResult?.data?.data?.TTPPM}
                                duration={0.5}
                              />
                            </span>
                          </div>
                        ) : (
                          <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                            <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={type === "all" ? "All Stock" : type}
                    className="touch-auto"
                    columns={type === "all" ? columns : columns1}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortAsc={false}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    striped={true}
                    responsive={true}
                    highlightOnHover
                    persistTableHead
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      if (!e?._id) return;
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                    footer={false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.designNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={false}
          isPreview={true}
          openInvoicePreview={{}}
          handleDelete={handleDelete}
          route={"matchingcarddashboardstock/update"}
        />
      ) : null}
      {isExportData && (
        <MatchingStockPreview
          isExportData={isExportData}
          setIsExportData={setIsExportData}
          isPartyPdf={isPartyPdf}
          matchingCard={cardData}
          selectedDateType={selectedType?.selectedDateType}
          allCount={
            type === "plan"
              ? count?.plan
              : type === "production"
              ? count?.production
              : type === "checking"
              ? count?.checking
              : type === "stitching"
              ? count?.stitching
              : type === "hand"
              ? count?.hand
              : type === "delivery"
              ? count?.delivery
              : count?.plan +
                count?.production +
                count?.checking +
                count?.stitching +
                count?.hand +
                count?.delivery
          }
          search={filterText}
          date={search}
          type={type}
        />
      )}
    </>
  );
}

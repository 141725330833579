import React, { useEffect, useRef, useState } from "react";
import Modal from "react-responsive-modal";
import ReactToPrint from "react-to-print";
import DataTableLoader from "../../page/DataTableLoader";
import { useLazyGetMatchingCardStockByKeywordQuery } from "../../../servicesRtkQuery/publicApi";
import moment from "moment";

export default function MatchingStockPreview(props: any) {
  const exportRef: any = useRef(null);
  const {
    isExportData,
    setIsExportData,
    selectedDateType,
    allCount,
    search,
    type,
    date,
    isPartyPdf
  } = props;
  const [cardTrigger, cardResult] = useLazyGetMatchingCardStockByKeywordQuery();
  const { isSuccess, isFetching, isError, error } = cardResult;
  const [cardData, setCardData]: any = useState([]);
  const [totalTPPM, setTotalTPPM] = useState(0); // Step 1: Initialize totalTPPM state variable

  useEffect(() => {
    if (isSuccess && !isFetching) {
      // const matchingCardStock:any = [...cardResult?.data?.data?.matchingCardStock]; // Create a shallow copy
      // const sortedData = matchingCardStock.sort((a: any, b: any) => {
      //   return new Date(a.receiveDate).getTime() - new Date(b.receiveDate).getTime();
      // });
      // setCardData(sortedData);
      setCardData(cardResult?.data?.data?.matchingCardStock);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    var searchData: any = {};
    Object.keys(date).map((key: any) => {
      if (date[key] && date[key].text !== "") {
        searchData[key] = date[key].text;
      }
    });

    if (!search && Object.keys(searchData).length === 0) {
      const types =
        selectedDateType?.map((item: any) => {
          return item?.value;
        }) ?? [];
      cardTrigger(
        types?.length === 0
          ? {
              stockStatus: type === "all" ? undefined : type,
              partyWise: isPartyPdf ? true : false,
              limit: allCount,
            }
          : { stockStatus: types, limit: allCount }
      );
    } else {
      const types =
        selectedDateType?.map((item: any) => {
          return item?.value;
        }) ?? [];
      cardTrigger(
        types?.length === 0
          ? {
              search: search ? search : undefined,
              ...searchData,
              stockStatus: type === "all" ? undefined : type,
              partyWise: isPartyPdf ? true : false,
              limit: allCount,
            }
          : {
              search: search ? search : undefined,
              ...searchData,
              stockStatus: types,
              partyWise: isPartyPdf ? true : false,
              limit: allCount,
            }
      );
    }
  }, [search, date, type]);
  useEffect(() => {
    let total = 0;
    cardData &&
      cardData.forEach((item: any) => {
        total += item?.matchingCard?.TPPM || 0;
      });
    setTotalTPPM(total);
  }, [cardData]);
  return (
    <div>
      <Modal
        open={isExportData}
        showCloseIcon={false}
        closeOnEsc={false}
        closeOnOverlayClick={false}
        classNames={{
          modal: "rounded-xl",
        }}
        styles={{
          overlay: {
            height: "auto",
            width: "auto",
          },
          modalContainer: {
            overflow: "auto",
          },
        }}
        onEscKeyDown={() => setIsExportData(false)}
        onOverlayClick={() => setIsExportData(false)}
        aria-labelledby="sample-card-pdf"
        aria-describedby="sample-card-pdf-description"
        animationDuration={400}
        onClose={() => setIsExportData(false)}
      >
        <div className="overflow-auto">
          <div className="text-center">
            <div className="bg-white border rounded-lg text-left shadow-xl">
              <div ref={exportRef} className={`a5-portrait overflow-auto`}>
                <div className="border border-gray-600">
                  <div className="font-bold items-center p-2 bg-gray-100 text-5">
                    <div className="text-themeColor capitalize text-center">
                      Stock Data {type}
                    </div>
                  </div>
                  {!isFetching ? (
                    <div className="">
                      <div className={`text-sm`}>
                        <table className="w-full border-collapse bg-white text-left">
                          <thead className="bg-gray-100 divide-black border-t border-black text-4">
                            <tr>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Sr No
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Rec Date
                              </th>
                              {/* <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Send Date
                              </th> */}
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Day
                              </th>
                              {type === "all" && (
                                <th
                                  scope="col"
                                  className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                                >
                                  Status Type
                                </th>
                              )}
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Type
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Card No
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Party name
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                design no
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 w-16 border-r text-red-800 border-black capitalize text-center"
                              >
                                challan no
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Fabric
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Cut
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Height
                              </th>
                              <th
                                scope="col"
                                className="py-1.5 border-r text-red-800 border-black capitalize text-center"
                              >
                                Total Pcs
                              </th>
                              <th
                                scope="col"
                                className="py-1 w-16 text-red-800 border-black capitalize text-center"
                              >
                                {" "}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y text-center divide-black border-t border-black text-4">
                            {cardData?.map((item: any, i: any) => {
                              return (
                                <>
                                  <tr key={i}>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                      {i + 1}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                      {item?.receiveDate ||
                                      item?.receiveDate !== null ||
                                      item?.receiveDate === "" ? (
                                        <>
                                          {moment(item?.receiveDate).format(
                                            "DD-MM-YYYY"
                                          )}
                                        </>
                                      ) : (
                                        "-"
                                      )}
                                    </td>
                                    {/* <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.stockStatus === "delivery" ? (
                                        <>
                                          {item?.matchingCard?.date ? (
                                            <>
                                              {moment(
                                                item?.matchingCard?.date
                                              ).format("DD-MM-YYYY")}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      ) : (
                                        <>
                                          {item?.date ? (
                                            <>
                                              {moment(item?.date).format(
                                                "DD-MM-YYYY"
                                              )}
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </>
                                      )}
                                    </td> */}
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.day}
                                    </td>
                                    {type === "all" && (
                                      <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                        {item?.stockStatus}
                                      </td>
                                    )}
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.type}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.invoiceNo}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.party?.name}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.designNo}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.challanNo}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.clothe?.name}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.CM}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.height}
                                    </td>
                                    <td className="gap-3 border-r border-black font-semibold text-gray-900 text-center capitalize">
                                      {item?.matchingCard?.TPPM}
                                    </td>
                                    <td className="gap-3 border-black font-semibold text-gray-900 text-center capitalize">
                                      {" "}
                                    </td>
                                  </tr>
                                </>
                              );
                            })}
                          </tbody>
                          <tfoot className="divide-y text-center divide-black bg-gray-100 border-t border-black text-4">
                            <tr>
                              <td
                                colSpan={type === "all" ? 12 : 11}
                                className="gap-3 border-r border-black font-semibold text-gray-900 capitalize"
                              >
                                Total{" "}
                              </td>
                              <td className="gap-3 border-r border-black font-semibold text-gray-900 capitalize">
                                {totalTPPM}
                              </td>
                            </tr>
                          </tfoot>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <DataTableLoader />
                  )}
                </div>
              </div>
              <div className="m-3 flex flex-col lg:flex-row gap-2">
                <div>
                  <ReactToPrint
                    trigger={() => (
                      <button className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2">
                        Print <i className="fas fa-file-download"></i>
                      </button>
                    )}
                    content={() => exportRef.current}
                    documentTitle={props.pageName}
                  />
                </div>
                <div>
                  <button
                    className="rounded-lg text-base capitalize bg-themeColor text-white font-medium py-2 px-8 mx-2 mb-2 lg:mb-0"
                    onClick={() => setIsExportData(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
}

import { useMemo, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component";
import {
  useUpdateSareeRateCardMutation,
  useLazyGetSareeRateCardByKeywordQuery,
  useLazyGetSareeRateCardQuery,
  useLazyGetLumpRateCardByKeywordQuery,
  useLazyGetLumpRateCardQuery,
  useUpdateLumpRateCardMutation,
  useLazyAllRateCardQuery,
} from "../../../servicesRtkQuery/publicApi";
import "react-responsive-modal/styles.css";
import { useNavigate } from "react-router";
import Swal from "sweetalert2";
import TextBox from "../../../components/form/TextBox";
import DataTableLoader from "../../../components/page/DataTableLoader";
import CountUp from "react-countup";
import { ERROR_MESSAGE_TIMEOUT } from "../../../utils/constants";
import { LumpRateCardPreview } from "../../../components/pdf/ratecard/lumpRateCardPreview";
import moment from "moment";
import customStyles from "../../../utils/globalFunctions";
import { TableAction } from "../../../components/modal/TableAction";

export default function LumpRateDashboard() {
  const [cardTrigger, cardResult] = useLazyGetLumpRateCardByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;

  const [trigger, result] = useLazyGetLumpRateCardQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [allTrigger, allResult] = useLazyAllRateCardQuery();
  const {
    isSuccess: isAllSuccess,
    isFetching: isAllFetching,
    isError: isAllError,
    error: allError,
  } = allResult;

  const [cardData, setCardData]: any = useState<any>([]);
  const [singleCard, setSingleCard]: any = useState<any>();
  const navigate = useNavigate();
  const [updateCard] = useUpdateLumpRateCardMutation();

  const [showLumpRatePreview, setShowLumpRatePreview] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");
  const [isSearching, setIsSearching]: any = useState(false);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState<number>(() => {
    const savedPage = JSON.parse(localStorage.getItem("defaultpage") || "{}");
    return savedPage.lumpRate ? Number(savedPage.lumpRate) : 1;
  });
  const [defaultPage, setdefaultPage] = useState<number | undefined>(undefined);

  // Counters
  const [dressCount, setDressCount]: any = useState(0);
  const [sareeCount, setSareeCount]: any = useState(0);
  const [lumpCount, setLumpCount]: any = useState(0);
  const [totalRows, setTotalRows] = useState(0);

  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage);
  };

  useEffect(() => {
    const savedData = JSON.parse(localStorage.getItem("defaultpage") || "{}");
    savedData.lumpRate = page;
    localStorage.setItem("defaultpage", JSON.stringify(savedData));
  }, [page]);

  useEffect(() => {
    const page = JSON.parse(localStorage.getItem("defaultpage") || "{}");
    if (page.lumpRate !== undefined) {
      setdefaultPage(Number(page.lumpRate));
    }
  }, []);

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage);
  };

  useEffect(() => {
    cardTrigger({
      page: page,
      limit: perPage,
    });
    allTrigger();
  }, []);

  const globalTriggers = (page: Number, perPage: Number) => {
    if (isSearching) {
      let searchData: any = {
        page: page,
        limit: perPage,
      };
      Object.keys(search).map((key: any) => {
        if (search[key] && search[key]?.text !== "") {
          searchData[key] = search[key]?.text;
        }
      });
      if (Object.keys(searchData).length != 0) {
        cardTrigger(searchData);
      }
    } else {
      cardTrigger({
        page: page,
        limit: perPage,
      });
    }
  };

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(cardResult?.data?.data?.lumpCard);
      setTotalRows(cardResult?.data?.data?.count);
    }
  }, [isCardSuccess, isCardFetching]);

  //#region Field Search section
  const [search, setSearch]: any = useState({
    cardNo: {
      text: "",
      status: false,
    },
    invoiceNo: {
      text: "",
      status: false,
    },
    date: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    rate: {
      text: "",
      status: false,
    },
    fabrics: {
      text: "",
      status: false,
    },
    total: {
      text: "",
      status: false,
    },
    hand: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
  });

  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]["status"],
      },
    });
  };

  // useEffect(() => {
  //     let searchData: any = {}
  //     Object.keys(search).map((key: any) => {
  //         if (search[key] && search[key].text !== "") {
  //             searchData[key] = search[key].text
  //         }
  //         setTotalRows(cardResult?.data?.data?.count)
  //     })
  //     searchData = { ...searchData }
  //     if (Object.keys(searchData).length != 0) {
  //         setIsSearching(true)
  //         cardTrigger(searchData)
  //     }
  //     else {
  //         setIsSearching(false)
  //         globalTriggers(page, perPage)
  //     }
  // }, [search])
  const delayedCardTrigger: any = useRef(null);
  const latestRequest: any = useRef(null);

  useEffect(() => {
    let searchData: any = {};

    Object.keys(search).forEach((key) => {
      if (search[key] && search[key]?.text !== "") {
        searchData[key] = search[key]?.text;
      }
    });

    searchData = { ...searchData };
    if (Object.keys(searchData).length !== 0) {
      setIsSearching(true);

      // cardTrigger(searchData)
      // Clear the previous timeout
      clearTimeout(delayedCardTrigger.current);

      // Create a new timeout for the current search
      delayedCardTrigger.current = setTimeout(() => {
        latestRequest.current = cardTrigger(searchData);
      }, 300);
    } else {
      setIsSearching(false);
      globalTriggers(page, perPage);
    }
    return () => {
      clearTimeout(delayedCardTrigger.current);
      if (latestRequest.current) {
        latestRequest.current.abort();
      }
    };
  }, [search]);
  const getFromLocalStorage = (keys: string[]) => {
    const storedData = localStorage.getItem("filterData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);

      setSearch(parsedData?.data);
    }
  };

  useEffect(() => {
    const componentKeys = [
      "cardNo",
      "date",
      "designNo",
      "fabrics",
      "fromDate",
      "hand",
      "invoiceNo",
      "partyName",
      "rate",
      "toDate",
      "total",
    ];
    getFromLocalStorage(componentKeys);
  }, []);
  const saveToLocalStorage = (data: any) => {
    let obj: any = { data };
    localStorage.setItem("filterData", JSON.stringify(obj));
  };
  const searchComponent = (key: string, label: string) => {
    return (
      <div className={`text-left`}>
        {search[key]?.status ? (
          <TextBox
            type={`${key !== "date" ? "text" : "date"}`}
            placeholder={label}
            autoFocus
            className="font-light"
            value={search[key]?.text}
            onChange={(e: any) => {
              const searchText = e.target.value;
              if (searchText.length === 0) {
                const updatedSearch = {
                  ...search,
                  [key]: {
                    text: "",
                    status: false,
                  },
                };
                setSearch(updatedSearch);
                const allTextsEmpty = Object.values(updatedSearch).every(
                  (item: any) => item?.text === ""
                );

                if (allTextsEmpty) {
                  localStorage.removeItem("filterData");
                } else {
                  saveToLocalStorage(updatedSearch);
                }
              } else {
                const updatedSearch = {
                  ...search,
                  [key]: {
                    text: searchText,
                    status: true,
                  },
                };
                setSearch(updatedSearch);
                saveToLocalStorage(updatedSearch);
              }
            }}
          />
        ) : (
          <div className="mb-2">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
              {/* <i className='fas fa-search ml-2 text-gray-600'></i> */}
            </div>
            {search[key]?.text !== "" && search[key]?.text && (
              <div className="flex justify-center items-center m-1 font-medium py-1 px-3 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="text-xs font-normal leading-none max-w-full flex-initial px-4">
                  {search[key]?.text}
                </div>
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                      const storedData: any =
                        localStorage.getItem("filterData");
                      const parsedData: any = JSON.parse(storedData);
                      if (
                        parsedData &&
                        parsedData.data &&
                        parsedData.data[key]
                      ) {
                        delete parsedData.data[key]?.text;
                        localStorage.setItem(
                          "filterData",
                          JSON.stringify(parsedData)
                        );
                      }
                      setCardData(cardResult?.data?.data?.lumpCard);
                    }}
                  ></i>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };
  //#endregion

  const columns: any = [
    {
      name: searchComponent("date", "Date"),
      selector: (row: { [x: string]: any }) =>
        moment(row.date).format("DD-MM-yyyy"),
    },
    {
      name: searchComponent("invoiceNo", "Card A"),
      selector: (row: { [x: string]: any }) => row?.invoiceNo,
    },
    // {
    //     name: searchComponent("cardNo", "Card B"),
    //     selector: (row: { [x: string]: any; }) => row?.cardNo,
    //
    // },
    {
      name: searchComponent("partyName", "Party Name"),
      selector: (row: { [x: string]: any }) => row?.party?.name,
    },
    {
      name: searchComponent("designNo", "Design No"),
      selector: (row: { [x: string]: any }) => row?.designNo,
    },
    {
      name: searchComponent("rate", "Rate"),
      selector: (row: { [x: string]: any }) => row?.rate,
    },
    {
      name: searchComponent("fabrics", "Fabrics Costing"),
      selector: (row: { [x: string]: any }) => row?.fabricsTotal,
    },
    {
      name: searchComponent("hand", "Hand Work"),
      selector: (row: { [x: string]: any }) => row?.handTotal,
    },
    {
      name: searchComponent("total", "Total Costing"),
      selector: (row: { [x: string]: any }) => row?.total,
    },
  ];

  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex flex-wrap gap-2">
        <div className="w-full md:w-max">
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (e.target.value.length == 0) {
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: e.target.value,
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className="fas fa-search w-4 fill-current"></i>
            </span>
            <input
              type="search"
              name="leadingIcon"
              id="leadingIcon"
              placeholder="Search here"
              value={filterText}
              onChange={(e: any) => {
                setFilterText(e.target.value);
              }}
              className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
            />
          </div>
        </div>
      </div>
    );
  }, [filterText, resetPaginationToggle]);

  useEffect(() => {
    if (filterText) {
      cardTrigger({
        search: filterText,
      });
    } else {
      cardTrigger({
        page: page,
        limit: perPage,
      });
    }
  }, [filterText]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setSingleCard(result?.data?.data?.lumpCard[0]);
      setShowLumpRatePreview(true);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isAllSuccess && !isAllFetching) {
      setDressCount(allResult?.data?.data?.dress);
      setSareeCount(allResult?.data?.data?.saree);
      setLumpCount(allResult?.data?.data?.lump);
    }
  }, [isAllSuccess, isAllFetching]);
  const openInvoicePreview = async (row: any) => {
    trigger(row?._id);
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            lrId: row._id,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Rate Card has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            cardTrigger({
              page: page,
              limit: perPage,
            });
            allTrigger();
          }
        });
      }
    });
  };

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-4 lg:gap-0 -mx-3">
            <div
              className="w-full max-w-full px-3 sm:flex-none cursor-pointer"
              onClick={() => {
                navigate("/sareeratedashboard");
                localStorage.removeItem("filterData");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                          Saree Rate Cards
                        </p>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={sareeCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:flex-none cursor-pointer"
              onClick={() => {
                navigate("/dressratecarddashboard");
                localStorage.removeItem("filterData");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-white`}
              >
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                          Dress Rate Cards
                        </p>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:flex-none cursor-pointer"
              onClick={() => {
                navigate("/lumpratedashboard");
                localStorage.removeItem("filterData");
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border bg-gradient-to-r from-yellow-100 to-orange-200 `}
              >
                <div className="flex-auto p-4">
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                          Lump Rate Cards
                        </p>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={lumpCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="px-3">
                <button
                  className="px-3 py-2 mr-2 mb-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                  type="button"
                  onClick={() => {
                    navigate("/ratecard/lump");
                  }}
                >
                  <i className="fas fa-folder-plus"></i>
                  <span className="ml-2">Add Lump Rate Card</span>
                </button>
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={"Saree Cards"}
                    className="touch-auto"
                    columns={columns}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isCardFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortFieldId="invoiceNo"
                    defaultSortAsc={true}
                    pagination
                    paginationServer
                    striped={true}
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    responsive={true}
                    persistTableHead={true}
                    highlightOnHover
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                    paginationDefaultPage={defaultPage}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.designNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={true}
          isPreview={true}
          openInvoicePreview={openInvoicePreview}
          handleDelete={handleDelete}
          route={"ratecard/lump"}
        />
      ) : null}
      {/* Pdf Preview */}
      {showLumpRatePreview && (
        <LumpRateCardPreview
          showLumpRatePreview={showLumpRatePreview}
          setShowLumpRatePreview={setShowLumpRatePreview}
          singleCard={singleCard}
          pageName="Lump Rate card"
        />
      )}
    </>
  );
}

import { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import {
  useLazyUserQuery,
  useAddUserMutation,
  useUpdateUserMutation,
} from "../../servicesRtkQuery/publicApi";
import Modal from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import Swal from "sweetalert2";
import { UserValidation } from "../../utils/validation";
import TextBox from "../../components/form/TextBox";
import Select from "../../components/form/Select";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import customStyles from "../../utils/globalFunctions";
import moment from "moment";
import { SettingTableAction } from "../../components/modal/SettingTableAction";

interface UserData {
  name: string;
  phoneNumber: string;
  email: string;
  password: string;
  gender: string;
  isActive: boolean;
  role: string;
  status: boolean;
  type: string;
}

export default function User() {
  const [trigger, result] = useLazyUserQuery();
  const { isSuccess, isFetching, isError, error } = result;
  const [userData, setUserData]: any = useState<any>([]);
  const [newUserData, setNewUserData] = useState<UserData>({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    gender: "Male",
    isActive: true,
    role: "admin",
    type: "all",
    status: true,
  });
  const [addUser, addResult] = useAddUserMutation();
  const [updateUser, updateResult] = useUpdateUserMutation();
  const [updateRow, setUpdateRows] = useState<any>();
  const [role, setRole] = useState<any>("");
  const [type, setType] = useState<any>("");
  const [gender, setGender] = useState<any>("");
  const [isActive, setIsActive] = useState<any>("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalPasswordOpen, setIsModalPasswordOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");
  const Close = (
    <i className="fas fa-times bg-black text-white rounded-full px-2 py-1.5"></i>
  );
  const [isAdd, setIsAdd] = useState<any>(false);

  useEffect(() => {
    trigger({});
  }, []);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      setUserData(result?.data?.data?.users);
    }
  }, [isSuccess, isFetching]);

  function editFunction(row: any) {
    setIsModalOpen(true);
    setUpdateRows(row);
    setIsAdd(false);
    setGender(row.gender);
    setIsActive(row.isActive);
    setRole(row.role);
    setType(row?.type);
    let updatedData: any = {};
    if (isModalPasswordOpen === true) {
      updatedData = {
        password: "",
      };
    } else {
      updatedData = {
        name: row.name,
        phoneNumber: row.phoneNumber,
        email: row.email,
        gender: row.gender,
        isActive: row.isActive,
        role: row.role,
        status: row.status,
        type: row?.type,
      };
    }
    setNewUserData(updatedData);
  }
  const columns: any = [
    {
      name: "Name",
      selector: (row: { [x: string]: any }) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row: { [x: string]: any }) => row.email,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row: { [x: string]: any }) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Role",
      selector: (row: { [x: string]: any }) => row.role,
      sortable: true,
    },
    {
      name: "Created At",
      selector: (row: { [x: string]: any }) =>
        moment(row?.createAt).format("DD-MM-YYYY"),
      sortable: true,
    },
  ];

  const handleUpdate = () => {
    const result: any = UserValidation(newUserData);
    if (result === true) {
      let userId = updateRow._id;
      let updatedData = {};
      if (isModalPasswordOpen === true) {
        updatedData = {
          password: newUserData?.password,
        };
      } else {
        updatedData = {
          name: newUserData.name,
          phoneNumber: newUserData.phoneNumber,
          email: newUserData.email,
          gender: newUserData?.gender,
          isActive: newUserData.isActive,
          role: newUserData.role,
          status: newUserData.status,
          type: newUserData?.type,
        };
      }
      updateUser({
        payload: updatedData,
        query: {
          userId: userId,
        },
      }).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          setIsModalOpen(false);
          setIsModalPasswordOpen(false);

          Swal.fire({
            toast: true,
            icon: "success",
            title: "User has been Successfully Updated!",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          trigger({});
        }
      });
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let userId = row._id;
        let updatedData = {
          name: row.name,
          phoneNumber: row.phoneNumber,
          email: row.email,
          gender: row.gender,
          isActive: row.isActive,
          role: row.role,
          status: false,
        };
        updateUser({
          payload: updatedData,
          query: {
            userId: userId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "User record has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            trigger({});
          }
        });
      }
    });
  };
  const onHandleChange = (e: any) => {
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e: any) => {
    const result: any = UserValidation(newUserData);
    if (result === true) {
      e.preventDefault();
      let updatedData = {};

      updatedData = {
        name: newUserData.name,
        phoneNumber: newUserData.phoneNumber,
        email: newUserData.email,
        password: newUserData?.password,
        gender: newUserData.gender,
        isActive: newUserData.isActive,
        type: newUserData?.type,
        role: newUserData.role,
      };
      addUser(updatedData).then((data: any) => {
        if (data.error) {
          Swal.fire({
            toast: true,
            icon: "error",
            title: data.error.data.message,
            position: "top-end",
            showConfirmButton: false,
            timer: ERROR_MESSAGE_TIMEOUT,
            timerProgressBar: true,
          });
        } else {
          setIsModalOpen(false);
          setIsModalPasswordOpen(false);
          Swal.fire({
            toast: true,
            icon: "success",
            title: "User has been Successfully Added!",
            position: "top-end",
            showConfirmButton: false,
            timer: 1000,
            timerProgressBar: true,
          });
          trigger({});
        }
      });
    } else {
      Swal.fire({
        toast: true,
        icon: "error",
        title: result.errorMessage,
        position: "top-end",
        showConfirmButton: false,
        timer: ERROR_MESSAGE_TIMEOUT,
        timerProgressBar: true,
      });
    }
  };

  const roleSelect = (e: any) => {
    let option = e.target.value;
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setRole(option);
  };
  const typeSelect = (e: any) => {
    let option = e.target.value;
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setType(option);
  };

  const genderSelect = (e: any) => {
    let option = e.target.value;
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setGender(option);
  };
  const activeSelect = (e: any) => {
    let option = e.target.value;
    setNewUserData({
      ...newUserData,
      [e.target.name]: e.target.value,
    });
    setIsActive(option);
  };

  return (
    <div className="m-0 mt-20 lg:mt-0 xl:mt-0 font-sans text-base antialiased font-normal leading-default relative xl:ml-30 rounded-xl flex bg-themeBgColor items-center p-0 bg-cover">
      <div className="w-full mx-6 overflow-hidden">
        <div className="w-full h-full my-6 rounded-xl bg-white">
          <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
            <div className="px-3">
              <button
                className="px-4 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                type="button"
                onClick={() => {
                  setIsModalOpen(true);
                  setIsAdd(true);
                  let updatedData = {
                    name: "",
                    phoneNumber: "",
                    email: "",
                    password: "",
                    gender: "Male",
                    isActive: true,
                    role: "admin",
                    type: "all",
                    status: true,
                  };
                  setIsActive("true");
                  setRole("admin");
                  setGender("Male");
                  setType("all");
                  setNewUserData(updatedData);
                }}
              >
                <i className="fas fa-user-plus"></i>
                <span className="ml-2">Add User</span>
              </button>
            </div>
            <div className="flex-auto px-0 pt-0 pb-2">
              <div className="px-4 overflow-x-auto">
                <DataTable
                  title="Users"
                  columns={columns}
                  data={userData}
                  fixedHeader={true}
                  defaultSortFieldId="id"
                  defaultSortAsc={false}
                  pagination
                  striped={true}
                  responsive={true}
                  highlightOnHover
                  persistTableHead
                  customStyles={customStyles}
                  onRowClicked={(e: any) => {
                    setSelectedRow(e);
                    setIsActionModalOpen(true);
                  }}
                  paginationPerPage={20}
                />
              </div>
            </div>
            {isModalOpen ? (
              <Modal
                open={isModalOpen}
                closeIcon={Close}
                onClose={() => setIsModalOpen(false)}
                center
                closeOnEsc={true}
                closeOnOverlayClick={false}
                classNames={{
                  modal: "rounded-xl",
                }}
                styles={{
                  overlay: {
                    height: "auto",
                    width: "auto",
                  },
                }}
              >
                <div>
                  <form className="w-96 p-3">
                    <div className="w-full p-2">
                      <TextBox
                        name="name"
                        label="Name"
                        id="name"
                        type="text"
                        placeholder=" "
                        value={newUserData.name}
                        onChange={(e: any) => onHandleChange(e)}
                      />
                    </div>
                    <div className="w-full p-2">
                      <TextBox
                        name="phoneNumber"
                        label="Phone Number"
                        id="phoneNumber"
                        type="text"
                        placeholder=" "
                        value={newUserData.phoneNumber}
                        onChange={(e: any) => onHandleChange(e)}
                      />
                    </div>
                    <div className="w-full p-2">
                      <TextBox
                        name="email"
                        label="Email"
                        id="email"
                        type="email"
                        placeholder=" "
                        value={newUserData.email}
                        onChange={(e: any) => onHandleChange(e)}
                        style={{ textTransform: "lowercase" }}
                      />
                    </div>
                    {isAdd ? (
                      <div className="w-full p-2">
                        <TextBox
                          name="password"
                          label="Password"
                          id="password"
                          type="password"
                          placeholder=" "
                          value={newUserData.password}
                          onChange={(e: any) => onHandleChange(e)}
                        />
                      </div>
                    ) : null}
                    <div className="flex mb-2">
                      <div className="w-full p-2">
                        <Select
                          label="Gender"
                          name="gender"
                          value={gender}
                          options={["Select", "Male", "Female", "Other"]}
                          onChange={(e: any) => genderSelect(e)}
                        />
                      </div>
                      <div className="w-full p-2">
                        <Select
                          label="Active"
                          name="isActive"
                          value={isActive}
                          options={["true", "false"]}
                          onChange={(e: any) => activeSelect(e)}
                        />
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-full p-2">
                        <Select
                          label="Role"
                          name="role"
                          value={role}
                          options={[
                            "Select",
                            "works",
                            "admin",
                            "designer",
                            "programmer",
                          ]}
                          onChange={(e: any) => roleSelect(e)}
                        />
                      </div>
                    </div>
                    <div className="flex mb-2">
                      <div className="w-full p-2">
                        <Select
                          label="Type"
                          name="type"
                          value={type}
                          options={[
                            "all",
                            "production",
                            "checking",
                            "hand",
                            "delivery",
                          ]}
                          onChange={(e: any) => typeSelect(e)}
                        />
                      </div>
                    </div>
                    {isAdd ? (
                      <button
                        className="px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        type="button"
                        onClick={handleSubmit}
                      >
                        Add User
                      </button>
                    ) : (
                      <button
                        className="px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        type="button"
                        onClick={handleUpdate}
                      >
                        Update User
                      </button>
                    )}
                  </form>
                </div>
              </Modal>
            ) : null}
            {isModalPasswordOpen ? (
              <Modal
                open={isModalPasswordOpen}
                closeIcon={Close}
                onClose={() => setIsModalPasswordOpen(false)}
                center
                closeOnEsc={true}
                closeOnOverlayClick={false}
                classNames={{
                  modal: "rounded-xl",
                }}
                styles={{
                  overlay: {
                    height: "auto",
                    width: "auto",
                  },
                }}
              >
                <form className="w-96 p-3">
                  <div className="w-full p-2">
                    <TextBox
                      name="password"
                      label="New Password"
                      id="password"
                      type="password"
                      placeholder=" "
                      value={newUserData?.password}
                      onChange={(e: any) => onHandleChange(e)}
                    />
                  </div>
                  {isAdd ? (
                    <button
                      className="px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                      type="button"
                      onClick={handleSubmit}
                    >
                      Add User
                    </button>
                  ) : (
                    <div className="flex justify-center">
                      <button
                        className="px-8 py-2 text-lg leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                        type="button"
                        onClick={handleUpdate}
                      >
                        New
                        {isModalPasswordOpen === true ? " Password" : "User"}
                      </button>
                    </div>
                  )}
                </form>
              </Modal>
            ) : null}
          </div>
        </div>
      </div>
      {isActionModalOpen ? (
        <SettingTableAction
          header={selectedRow?.name}
          isActionModalOpen={isActionModalOpen}
          setIsActionModalOpen={setIsActionModalOpen}
          setIsModalPasswordOpen={setIsModalPasswordOpen}
          editFunction={editFunction}
          selectedRow={selectedRow}
          handleDelete={handleDelete}
          setIsModalOpen={setIsModalOpen}
        />
      ) : null}
    </div>
  );
}

import { useMemo, useState, useEffect, useRef } from "react";
import DataTable from "react-data-table-component-footer";
import {
  useLazyMatchingCardQuery,
  useUpdateMatchingCardMutation,
  useLazyCopyMatchingCardQuery,
  useLazyGetMatchingCardByKeywordQuery,
  useLazyGetMatchingCardQuery,
  useLazyGetMatchingCardCountQuery,
  useLazySingleSettingQuery,
} from "../../servicesRtkQuery/publicApi";
import "react-responsive-modal/styles.css";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import { setMatchingCard } from "../../redux/matchingCardSlice";
import Swal from "sweetalert2";
import { MatchingCardPreview } from "../../components/pdf/matchingcardPreview";
import TextBox from "../../components/form/TextBox";
import CountUp from "react-countup";
import DataTableLoader from "../../components/page/DataTableLoader";
import { LumpMatchingCardPreview } from "../../components/pdf/lumpMatchingCardPreview";
import { SareeMatchingCardPreview } from "../../components/pdf/sareeMatchingCard";
import { ERROR_MESSAGE_TIMEOUT } from "../../utils/constants";
import moment from "moment";
import customStyles from "../../utils/globalFunctions";
import { TableAction } from "../../components/modal/TableAction";
import SareeMatchingCardPdf from "../../components/pdf/dashboard/SareeMatchingCardPdf";
import { MatchingCardDataPdf } from "../../components/pdf/dashboard/matchingCardDataPdf";
import { m } from "framer-motion";
import Select from "react-select";

export default function MatchingCardDashboard() {
  const dispatch = useDispatch();
  const [trigger, result] = useLazyMatchingCardQuery();
  const { isSuccess, isFetching, isError, error } = result;

  const [settingTrigger, settingResult] = useLazySingleSettingQuery();
  const { isSuccess: isSettingSuccess, isFetching: isSettingFetching } =
    settingResult;

  var userData: any = localStorage.getItem("user");
  userData = JSON.parse(userData);

  const [matchingTrigger, matchingResult] = useLazyGetMatchingCardQuery();
  const {
    isSuccess: isMatchingSuccess,
    isFetching: isMatchingFetching,
    isError: isMatchingError,
    error: matchingError,
  } = matchingResult;

  const [copyTrigger, copyResult] = useLazyCopyMatchingCardQuery();
  const {
    isSuccess: isCopySuccess,
    isFetching: isCopyFetching,
    isError: isCopyError,
    error: copyError,
  } = copyResult;

  const [countTrigger, countResult] = useLazyGetMatchingCardCountQuery();
  const {
    isSuccess: isCountSuccess,
    isFetching: isCountFetching,
    isError: isCountError,
    error: countError,
  } = countResult;


  const [cardTrigger, cardResult] = useLazyGetMatchingCardByKeywordQuery();
  const {
    isSuccess: isCardSuccess,
    isFetching: isCardFetching,
    isError: isCardError,
    error: cardError,
  } = cardResult;

  const [cardData, setCardData]: any = useState<any>([]);
  const navigate = useNavigate();
  const [updateCard] = useUpdateMatchingCardMutation();
  const [showMatchingPreview, setShowMatchingPreview] = useState(false);
  const [singleCard, setSingleCard]: any = useState<any>();

  const matchingCardData = useSelector(
    (state: any) => state.matchingCardSlice.matchingCardData
  );

  // Counters
  const [allCount, setAllCount]: any = useState(0);
  const [dressCount, setDressCount]: any = useState(0);
  const [sareeCount, setSareeCount]: any = useState(0);
  const [lumpCount, setLumpCount]: any = useState(0);
  const [totalTPPMCount, setTotalTPPMCount]: any = useState(0);

  // Filtering states
  const [filterText, setFilterText] = useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  //Pagination
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(20);
  const [page, setPage] = useState(1);
  const urlData: any = useLocation().search;
  const dashboardType = new URLSearchParams(urlData).get("type");
  const [type, setType]: any = useState(dashboardType ? dashboardType : "all");
  const [isSearching, setIsSearching]: any = useState(false);
  const [isExportData, setIsExportData] = useState(false);
  const [isPartyPdf, setIsPartyPdf] = useState(false);
  const [isExportSareeData, setIsExportSareeData] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRow, setSelectedRow]: any = useState("");
  const [reminderDays, setReminderDays]: any = useState(0);

  useEffect(() => {
    settingTrigger("reminderDays");
  }, []);

  useEffect(() => {
    if (isSettingSuccess && !isSettingFetching) {
      setReminderDays(settingResult?.data?.data?.setting[0]?.metaValue[0] ?? 0);
    }
  }, [isSettingSuccess, isSettingFetching]);

  const [search, setSearch]: any = useState({
    cardNo: {
      text: "",
      status: false,
    },
    invoiceNo: {
      text: "",
      status: false,
    },
    partyName: {
      text: "",
      status: false,
    },
    designNo: {
      text: "",
      status: false,
    },
    type: {
      text: "",
      status: false,
    },
    challanNo: {
      text: "",
      status: false,
    },
    TPPM: {
      text: "",
      status: false,
    },
    totalDays: {
      text: "",
      status: false,
    },
    clotheName: {
      text: "",
      status: false,
    },
    clothePartyName: {
      text: "",
      status: false,
    },
    mtrRate: {
      text: "",
      status: false,
    },
    CM: {
      text: "",
      status: false,
    },
    height: {
      text: "",
      status: false,
    },
    jobRate: {
      text: "",
      status: false,
    },
    date: {
      text: "",
      status: false,
    },
    fromDate: {
      text: "",
      status: false,
    },
    fromDeliveryDate: {
      text: "",
      status: false,
    },
    toDate: {
      text: "",
      status: false,
    },
    toDeliveryDate: {
      text: "",
      status: false,
    },
    delivery: {
      text: "",
      status: false,
    },
    partyDesignNo: {
      text: "",
      status: false,
    },
  });

  //#region API triggers
  useEffect(() => {
    countTrigger();
    if (matchingCardData?.matchingCard) {
      setCardData(matchingCardData?.matchingCard);
      // } else {
      //   trigger({
      //     page: page,
      //     limit: perPage,
      //   });
    }
  }, []);

  const globalTriggers = (page: number, perPage: number) => {
    const searchData: any = {
      page,
      limit: perPage,
      ...(type !== "all" && { type }),
    };

    if (isSearching) {
      Object.entries(search).forEach(([key, value]: any) => {
        if (value && value.text !== "") {
          searchData[key] = value.text;
        }
      });
    }

    const hasValidSearchData = Object.values(searchData).some(
      (value) =>
        typeof value === "string" && value !== "" && value !== "undefined"
    );

    if (isSearching && hasValidSearchData) {
      cardTrigger(searchData);
    } else {
      cardTrigger({
        page,
        limit: perPage,
        ...(type !== "all" && { type }),
      });

      if (type === "all") {
        localStorage.removeItem("filterData");
      }
    }
  };

  useEffect(() => {
    setResetPaginationToggle(!resetPaginationToggle);
    globalTriggers(page, perPage);
  }, [type]);

  useEffect(() => {
    if (isSuccess && !isFetching) {
      if (!isSearching) {
        dispatch(setMatchingCard(result?.data?.data));
      }
      setCardData(result?.data?.data?.matchingCard);
      setTotalRows(result?.data?.data?.count);
      setTotalTPPMCount(result?.data?.data?.TTPPM);
    }
  }, [isSuccess, isFetching]);

  useEffect(() => {
    if (isCountSuccess && !isCountFetching) {
      setAllCount(countResult?.data?.data?.all);
      setTotalRows(countResult?.data?.data?.all);
      setDressCount(countResult?.data?.data?.dress);
      setSareeCount(countResult?.data?.data?.saree);
      setLumpCount(countResult?.data?.data?.lump);
    }
  }, [isCountSuccess, isCountFetching]);

  useEffect(() => {
    if (isCardSuccess && !isCardFetching) {
      setCardData(cardResult?.data?.data?.matchingCard);
      setTotalRows(cardResult?.data?.data?.count);
      setTotalTPPMCount(cardResult?.data?.data?.TTPPM);
      // if (!isSearching) {
      //     dispatch(setMatchingCard(cardResult?.data?.data))
      // }
    }
  }, [isCardSuccess, isCardFetching]);

  useEffect(() => {
    if (isCopySuccess && !isCopyFetching) {
      localStorage.setItem(
        "copy-number",
        copyResult?.data?.data[0]?.invoiceNo +
          "-" +
          copyResult?.data?.data[0]?.cardNo
      );
      localStorage.setItem("invoices", JSON.stringify(copyResult?.data?.data));
      navigate({
        pathname: `/matchingcards/copy`,
      });
    }
  }, [isCopySuccess, isCopyFetching]);

  useEffect(() => {
    if (isMatchingSuccess && !isMatchingFetching) {
      setSingleCard(matchingResult.data.data.matchingCard[0]);
    }
  }, [isMatchingSuccess, isMatchingFetching]);
  //#endregion

  //#region Field Search section

  const onSearch = (key: string) => {
    let searchData = search;
    setIsSearching(true);
    Object.keys(searchData).forEach((searchkey: any, value: any) => {
      let item = searchData[searchkey];
      item["status"] = false;
      return item;
    });
    setSearch({
      ...searchData,
      [key]: {
        ...search[key],
        status: !search[key]?.["status"],
      },
    });
  };

  const delayedCardTrigger: any = useRef(null);
  const latestRequest: any = useRef(null);

  useEffect(() => {
    console.log("search", search);
    let searchData: any = {};
    Object.keys(search).map((key: any) => {
      if (key === "deliveryDate" && search[key].text === "") {
        cardTrigger(searchData);
      } else if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
      setTotalRows(result?.data?.data?.count);
    });

    Object.keys(search).forEach((key) => {
      if (search[key] && search[key].text !== "") {
        searchData[key] = search[key].text;
      }
    });

    if (Object.keys(searchData).length !== 0) {
      if (type !== "all") {
        searchData = { ...searchData, type: type };
      }

      setIsSearching(true);

      // Clear the previous timeout
      clearTimeout(delayedCardTrigger.current);

      // Create a new timeout for the current search
      delayedCardTrigger.current = setTimeout(() => {
        latestRequest.current = cardTrigger(searchData);
      }, 300);
    } else {
      setIsSearching(false);
      globalTriggers(page, perPage);
    }
    return () => {
      clearTimeout(delayedCardTrigger.current);
      if (latestRequest.current) {
        latestRequest.current.abort();
      }
    };
  }, [search]);

  const getFromLocalStorage = (keys: string[]) => {
    const storedData = localStorage.getItem("filterData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      // keys.forEach(key => {
      //   if (parsedData?.data[key]) {
      //     parsedData.data[key].status = false;
      //   }
      // });
      setSearch(parsedData?.data);
    }
  };

  useEffect(() => {
    const componentKeys = [
      "date",
      "invoiceNo",
      "cardNo",
      "partyName",
      "designNo",
      "type",
      "challanNo",
      "TPPM",
      "jobRate",
      "clotheName",
      "clothePartyName",
      "mtrRate",
      "delivery",
      "CM",
      "height",
      "partyDesignNo",
    ];
    getFromLocalStorage(componentKeys);
  }, []);
  const saveToLocalStorage = (data: any) => {
    let obj: any = { data };
    localStorage.setItem("filterData", JSON.stringify(obj));
  };

  const searchComponent = (key: string, label: string) => {
    return (
      <div className={`text-left`}>
        {search[key]?.status ? (
          <TextBox
            type={"text"}
            placeholder={label}
            autoFocus
            className="font-light"
            value={search[key].text}
            onChange={(e: any) => {
              const searchText = e.target.value;
              if (searchText.length === 0) {
                const updatedSearch = {
                  ...search,
                  [key]: {
                    text: "",
                    status: false,
                  },
                };
                setSearch(updatedSearch);
                const allTextsEmpty = Object.values(updatedSearch).every(
                  (item: any) => item?.text === ""
                );

                if (allTextsEmpty) {
                  localStorage.removeItem("filterData");
                } else {
                  saveToLocalStorage(updatedSearch);
                }

                setCardData(result?.data?.data?.matchingCard);
                setTotalRows(result?.data?.data?.count);
              } else {
                const updatedSearch = {
                  ...search,
                  [key]: {
                    text: searchText,
                    status: true,
                  },
                };
                setSearch(updatedSearch);
                saveToLocalStorage(updatedSearch);
              }
            }}
          />
        ) : (
          <div className="mb-2 flex flex-col">
            <div
              className="mb-1 cursor-pointer"
              onClick={() => {
                onSearch(key);
              }}
            >
              {label}
            </div>
            {search[key]?.text !== "" && search[key]?.text && (
              <div className="flex justify-center items-center m-1 font-medium py-1 rounded-full text-indigo-100 bg-themeColor border border-themeCol-700">
                <div className="flex flex-auto flex-row-reverse">
                  <i
                    className="fa fa-close mx-2 text-white cursor-pointer rounded-full w-4 h-4"
                    onClick={() => {
                      setSearch({
                        ...search,
                        [key]: {
                          text: "",
                          status: false,
                        },
                      });
                      const storedData: any =
                        localStorage.getItem("filterData");
                      const parsedData: any = JSON.parse(storedData);
                      if (
                        parsedData &&
                        parsedData.data &&
                        parsedData.data[key]
                      ) {
                        delete parsedData.data[key]?.text;
                        localStorage.setItem(
                          "filterData",
                          JSON.stringify(parsedData)
                        );
                      }
                    }}
                  ></i>
                </div>
                <div className="text-xs font-normal leading-none max-w-full flex-initial pr-4">
                  {search[key]?.text}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const [selectedType, setSelectedType] = useState({
    type: [
      {
        label: "Date",
        value: "allDate",
      },
      {
        label: "Delivery Date",
        value: "deliveryDate",
      },
    ],
    selectedDateType: { label: "Date", value: "allDate" },
  });

  const columns: any = [
    {
      name: searchComponent("date", "Date"),
      selector: (row: { [x: string]: any }) =>
        row?.date ? moment(row?.date).format("DD-MM-yyyy") : "",
      width: "7%",
    },
    {
      name: searchComponent("days", "Days"),
      selector: (row: { [x: string]: any }) =>
        row?.date && row?.delivery === "pending"
          ? Math.ceil(
              (new Date().getTime() - new Date(row.date).getTime()) /
                (1000 * 3600 * 24)
            )
          : "-",
      cell: (row: { [x: string]: any }) => {
        const days =
          row?.date && row?.delivery === "pending"
            ? Math.ceil(
                (new Date().getTime() - new Date(row.date).getTime()) /
                  (1000 * 3600 * 24)
              )
            : "-";
        return (
          <span style={{ color: days > reminderDays ? "red" : "inherit" }}>
            {days}
          </span>
        );
      },
      width: "4%",
    },
    {
      name: searchComponent("invoiceNo", "Card A"),
      selector: (row: { [x: string]: any }) => row?.invoiceNo,
      // width: "6%",
    },
    {
      name: searchComponent("cardNo", "Card B"),
      selector: (row: { [x: string]: any }) => row?.cardNo,
      width: "3%",
    },
    {
      selector: (row: { [x: string]: any }) => row?.party?.name,
      name: searchComponent("partyName", "Party Name"),
      // width: "8%",
    },
    {
      name: searchComponent("designNo", "Design No"),
      selector: (row: { [x: string]: any }) => row?.designNo,
      // width: "8%",
    },
    type === "saree" || type === "dress" || type === "lump"
      ? {
          name: "Type",
          selector: (row: { [x: string]: any }) => row.type,
        }
      : {
          name: searchComponent("type", "Type"),
          selector: (row: { [x: string]: any }) => row?.type,
          // width: "5%",
        },

    {
      name: searchComponent("challanNo", "Challan No"),
      selector: (row: { [x: string]: any }) => row?.challanNo,
      // width: "8%",
    },
    {
      name: searchComponent("TPPM", "Total Pcs."),
      selector: (row: { [x: string]: any }) => row?.TPPM,
      // width: "7%",
    },
    {
      name: searchComponent("clotheName", "Fabric"),
      selector: (row: { [x: string]: any }) => row?.clothe?.name,
      // width: "6%",
    },
    {
      name: searchComponent("clothePartyName", "Fabric Party"),
      selector: (row: { [x: string]: any }) => row?.clotheParty?.name,
      // width: "10%",
    },
    {
      name: searchComponent("mtrRate", "Mtr Rate"),
      selector: (row: { [x: string]: any }) => row?.mtrRate,
      // width: "10%",
    },
    {
      name: "Delivery Time",
      selector: (row: { [x: string]: any }) => {
        if (row?.deliveryDate && row?.date) {
          const deliveryDate = moment(row?.deliveryDate);
          const date = moment(row?.date);
          return deliveryDate.diff(date, "days");
        }
        return "-";
      },
      // width:"3%"
    },
    {
      name: searchComponent("delivery", "Delivery"),
      selector: (row: { [x: string]: any }) => row?.delivery,
      // width: "10%",
    },
    {
      name: searchComponent("deliveryDate", "Delivery Date"),
      selector: (row: { [x: string]: any }) =>
        row?.deliveryDate
          ? moment(row?.deliveryDate).format("DD-MM-YYYY")
          : "-",
      width: "8%",
    },
    {
      name: searchComponent("CM", "Cut"),
      selector: (row: { [x: string]: any }) => row.CM,
      // width: "6%",
    },
    {
      name: searchComponent("height", "Height"),
      selector: (row: { [x: string]: any }) => row.height,
      // width: "7%",
    },
    {
      name: "",
      selector: (row: { [x: string]: any }) =>
        row.repeat === true ? "repeat" : "",
    },
  ];

  if (type === "saree") {
    columns.splice(17, 0, {
      name: searchComponent("partyDesignNo", "Party Design Number"),
      selector: (row: { [x: string]: any }) => row.partyDesignNo,
    });
  }

  if (userData?.role !== "designer") {
    columns.splice(9, 0, {
      name: searchComponent("jobRate", "Rate"),
      selector: (row: { [x: string]: any }) => row?.jobRate,
      // width: "4%",
    });
  }

  //#region Global searving section
  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };

    return (
      <div className="flex flex-wrap gap-2 items-center">
        {/* <button
          className="w-full md:w-max justify-start"
          onClick={() => {
            setResetPaginationToggle(!resetPaginationToggle);
            setSearch({
              cardNo: {
                text: "",
                status: false,
              },
              invoiceNo: {
                text: "",
                status: false,
              },
              partyName: {
                text: "",
                status: false,
              },
              designNo: {
                text: "",
                status: false,
              },
              type: {
                text: "",
                status: false,
              },
              challanNo: {
                text: "",
                status: false,
              },
              TPPM: {
                text: "",
                status: false,
              },
              totalDays: {
                text: "",
                status: false,
              },
              clotheName: {
                text: "",
                status: false,
              },
              jobRate: {
                text: "",
                status: false,
              },
              clothePartyName: {
                text: "",
                status: false,
              },
              mtrRate: {
                text: "",
                status: false,
              },
              CM: {
                text: "",
                status: false,
              },
              height: {
                text: "",
                status: false,
              },
              date: {
                text: "",
                status: false,
              },
              fromDate: {
                text: "",
                status: false,
              },
              toDate: {
                text: "",
                status: false,
              },
              delivery: {
                text: "",
                status: false,
              },
            });
            handleClear();
          }}
        >
          <i className="fas fa-filter" title="Clear Filter"></i>
        </button> */}
        <div className="w-full md:w-44">
          <Select
            name="fromdeliveryDate"
            classNamePrefix="customSelect"
            className="block z-20 w-full text-sm text-gray-900 bg-transparent rounded-lg border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-themeColor"
            onChange={async (e: any) => {
              if (e) {
                setSelectedType({
                  ...selectedType,
                  selectedDateType: { label: e.label, value: e.value },
                });
                if (e.value === "allDate") {
                  setIsSearching(true);
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      fromDate: {
                        text: previous?.fromDeliveryDate?.text,
                        status: false,
                      },
                      toDate: {
                        text: previous?.toDeliveryDate?.text,
                        status: false,
                      },
                      fromDeliveryDate: {
                        text: "",
                        status: false,
                      },
                      toDeliveryDate: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                } else if (e.value === "deliveryDate") {
                  setIsSearching(true);
                  setSearch((previous: any) => {
                    return {
                      ...previous,
                      fromDeliveryDate: {
                        text: previous?.fromDate?.text,
                        status: false,
                      },
                      toDeliveryDate: {
                        text: previous?.toDate?.text,
                        status: false,
                      },
                      fromDate: {
                        text: "",
                        status: false,
                      },
                      toDate: {
                        text: "",
                        status: false,
                      },
                    };
                  });
                }
              }
            }}
            value={selectedType?.selectedDateType}
            options={selectedType?.type?.filter(
              (option: any) =>
                option.value !== selectedType?.selectedDateType?.value
            )}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="fromDate"
            type="date"
            placeholder=" "
            label="From"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (selectedType?.selectedDateType?.value === "deliveryDate") {
                setIsSearching(true);
                setSearch((previos: any) => {
                  return {
                    ...previos,
                    fromDeliveryDate: {
                      text: e.target.value,
                      status: false,
                    },
                    fromDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else if (selectedType?.selectedDateType?.value === "allDate") {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    fromDate: {
                      text: e.target.value,
                      status: false,
                    },
                    fromDeliveryDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="w-full md:w-max">
          <TextBox
            id="toDate"
            type="date"
            placeholder=" "
            label="To"
            aria-label="Search Input"
            onChange={(e: any) => {
              if (selectedType?.selectedDateType?.value === "allDate") {
                setIsSearching(true);
                setSearch((previous: any) => {
                  return {
                    ...previous,
                    toDate: {
                      text: e.target.value,
                      status: false,
                    },
                    toDeliveryDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              } else if (
                selectedType?.selectedDateType?.value === "deliveryDate"
              ) {
                setIsSearching(true);
                setSearch((previos: any) => {
                  return {
                    ...previos,
                    toDeliveryDate: {
                      text: e.target.value,
                      status: false,
                    },
                    toDate: {
                      text: "",
                      status: false,
                    },
                  };
                });
              }
            }}
          />
        </div>
        <div className="md:block w-full md:w-max">
          <div className="relative flex items-center text-gray-400 focus-within:text-themeColor">
            <span className="absolute left-4 h-6 flex items-center pr-3 border-r border-gray-300">
              <i className="fas fa-search w-4 fill-current"></i>
            </span>
            <input
              type="search"
              name="leadingIcon"
              id="leadingIcon"
              placeholder="Search here"
              value={filterText}
              onChange={(e: any) => {
                setFilterText(e.target.value);
              }}
              className="w-full pl-14 pr-4 py-2 rounded-xl text-sm text-gray-600 outline-none border border-gray-500 focus:border-themeColor transition"
            />
          </div>
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
              setIsPartyPdf(true);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Party Pdf</span>
          </button>
        </div>
        <div>
          <button
            className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
            type="button"
            onClick={() => {
              setIsExportData(true);
              setIsPartyPdf(false);
            }}
          >
            <i className="fas fa-file"></i>
            <span className="ml-2">Export Pdf</span>
          </button>
        </div>
        {type === "saree" && userData?.role !== "designer" ? (
          <div>
            <button
              className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
              type="button"
              onClick={() => {
                setIsExportSareeData(true);
              }}
            >
              <i className="fas fa-file"></i>
              <span className="ml-2">Report Pdf</span>
            </button>
          </div>
        ) : null}
      </div>
    );
  }, [filterText, resetPaginationToggle, selectedType]);

  useEffect(() => {
    if (filterText) {
      if (type === "all") {
        cardTrigger({
          search: filterText,
        });
      } else {
        cardTrigger({
          search: filterText,
          type: type,
        });
      }
    } else {
      globalTriggers(page, perPage);
    }
  }, [filterText]);
  //#endregion

  const openInvoicePreview = async (row: any) => {
    setShowMatchingPreview(true);
    if (isSearching) {
      let payload = {
        mcId: row._id,
        query: {
          isSingle: true,
        },
      };
      matchingTrigger(payload);
    } else {
      setSingleCard(row);
    }
  };

  const handleDelete = (row: any) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
    }).then((willDelete) => {
      if (willDelete.isConfirmed) {
        let mcId = row._id;
        let updatedData = {
          status: false,
        };
        updateCard({
          payload: updatedData,
          query: {
            mcId: mcId,
          },
        }).then((data: any) => {
          if (data.error) {
            Swal.fire({
              toast: true,
              icon: "error",
              title: data.error.data.message,
              position: "top-end",
              showConfirmButton: false,
              timer: ERROR_MESSAGE_TIMEOUT,
              timerProgressBar: true,
            });
          } else {
            Swal.fire({
              toast: true,
              icon: "success",
              title: "Card has been deleted!",
              position: "top-end",
              showConfirmButton: false,
              timer: 1000,
              timerProgressBar: true,
            });
            globalTriggers(page, perPage);
          }
        });
      }
    });
  };

  //#region Pagination section
  const handlePageChange = (page: any) => {
    setFilterText("");
    setPage(page);
    globalTriggers(page, perPage);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setFilterText("");
    setPage(page);
    setPerPage(newPerPage);
    globalTriggers(page, newPerPage);
  };
  //#endregion

  const footer = {
    invoiceNo: "Total",
    TPPM: totalTPPMCount,
  };

  return (
    <>
      <div className="m-0 mt-20 lg:mt-0 xl:mt-0 py-5 font-sans text-base antialiased font-normal min-h-screen leading-default relative xl:ml-30 rounded-xl">
        <div className="w-full px-6 mx-auto">
          <div className="flex flex-wrap -mx-3">
            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("all");
                navigate("/matchingcarddashboard");
                localStorage.removeItem("filterData");
                setSearch({
                  cardNo: {
                    text: "",
                    status: false,
                  },
                  invoiceNo: {
                    text: "",
                    status: false,
                  },
                  partyName: {
                    text: "",
                    status: false,
                  },
                  designNo: {
                    text: "",
                    status: false,
                  },
                  type: {
                    text: "",
                    status: false,
                  },
                  challanNo: {
                    text: "",
                    status: false,
                  },
                  TPPM: {
                    text: "",
                    status: false,
                  },
                  totalDays: {
                    text: "",
                    status: false,
                  },
                  clotheName: {
                    text: "",
                    status: false,
                  },
                  jobRate: {
                    text: "",
                    status: false,
                  },
                  clothePartyName: {
                    text: "",
                    status: false,
                  },
                  mtrRate: {
                    text: "",
                    status: false,
                  },
                  CM: {
                    text: "",
                    status: false,
                  },
                  height: {
                    text: "",
                    status: false,
                  },
                  date: {
                    text: "",
                    status: false,
                  },
                  fromDate: {
                    text: "",
                    status: false,
                  },
                  toDate: {
                    text: "",
                    status: false,
                  },
                  delivery: {
                    text: "",
                    status: false,
                  },
                  partyDesignNo: {
                    text: "",
                    status: false,
                  },
                });
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "all"
                    ? "bg-gradient-to-r from-blue-100 to-violet-100"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    All Matching Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={allCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-blue-500 to-violet-500">
                        <i className="leading-none fas fa-layer-group text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("dress");
                navigate("/matchingcarddashboard?type=dress");
                localStorage.removeItem("filterData");
                setSearch({
                  cardNo: {
                    text: "",
                    status: false,
                  },
                  invoiceNo: {
                    text: "",
                    status: false,
                  },
                  partyName: {
                    text: "",
                    status: false,
                  },
                  designNo: {
                    text: "",
                    status: false,
                  },
                  type: {
                    text: "",
                    status: false,
                  },
                  challanNo: {
                    text: "",
                    status: false,
                  },
                  TPPM: {
                    text: "",
                    status: false,
                  },
                  totalDays: {
                    text: "",
                    status: false,
                  },
                  clotheName: {
                    text: "",
                    status: false,
                  },
                  clothePartyName: {
                    text: "",
                    status: false,
                  },
                  mtrRate: {
                    text: "",
                    status: false,
                  },
                  CM: {
                    text: "",
                    status: false,
                  },
                  height: {
                    text: "",
                    status: false,
                  },
                  jobRate: {
                    text: "",
                    status: false,
                  },
                  date: {
                    text: "",
                    status: false,
                  },
                  fromDate: {
                    text: "",
                    status: false,
                  },
                  toDate: {
                    text: "",
                    status: false,
                  },
                  delivery: {
                    text: "",
                    status: false,
                  },
                  partyDesignNo: {
                    text: "",
                    status: false,
                  },
                });
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "dress"
                    ? "bg-gradient-to-r from-yellow-100 to-red-100"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Dress Matching Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={dressCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("saree");
                navigate("/matchingcarddashboard?type=saree");
                localStorage.removeItem("filterData");
                setSearch({
                  cardNo: {
                    text: "",
                    status: false,
                  },
                  invoiceNo: {
                    text: "",
                    status: false,
                  },
                  partyName: {
                    text: "",
                    status: false,
                  },
                  designNo: {
                    text: "",
                    status: false,
                  },
                  type: {
                    text: "",
                    status: false,
                  },
                  challanNo: {
                    text: "",
                    status: false,
                  },
                  TPPM: {
                    text: "",
                    status: false,
                  },
                  totalDays: {
                    text: "",
                    status: false,
                  },
                  clotheName: {
                    text: "",
                    status: false,
                  },
                  clothePartyName: {
                    text: "",
                    status: false,
                  },
                  mtrRate: {
                    text: "",
                    status: false,
                  },
                  CM: {
                    text: "",
                    status: false,
                  },
                  height: {
                    text: "",
                    status: false,
                  },
                  jobRate: {
                    text: "",
                    status: false,
                  },
                  date: {
                    text: "",
                    status: false,
                  },
                  fromDate: {
                    text: "",
                    status: false,
                  },
                  toDate: {
                    text: "",
                    status: false,
                  },
                  delivery: {
                    text: "",
                    status: false,
                  },
                  partyDesignNo: {
                    text: "",
                    status: false,
                  },
                });
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "saree"
                    ? "bg-gradient-to-r from-blue-100 to-teal-200"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Saree Matching Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={sareeCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="w-full max-w-full px-3 sm:w-1/2 sm:flex-none xl:w-1/4 cursor-pointer"
              onClick={() => {
                setType("lump");
                navigate("/matchingcarddashboard?type=lump");
                localStorage.removeItem("filterData");
                setSearch({
                  cardNo: {
                    text: "",
                    status: false,
                  },
                  invoiceNo: {
                    text: "",
                    status: false,
                  },
                  partyName: {
                    text: "",
                    status: false,
                  },
                  designNo: {
                    text: "",
                    status: false,
                  },
                  type: {
                    text: "",
                    status: false,
                  },
                  challanNo: {
                    text: "",
                    status: false,
                  },
                  TPPM: {
                    text: "",
                    status: false,
                  },
                  totalDays: {
                    text: "",
                    status: false,
                  },
                  clotheName: {
                    text: "",
                    status: false,
                  },
                  clothePartyName: {
                    text: "",
                    status: false,
                  },
                  mtrRate: {
                    text: "",
                    status: false,
                  },
                  CM: {
                    text: "",
                    status: false,
                  },
                  height: {
                    text: "",
                    status: false,
                  },
                  jobRate: {
                    text: "",
                    status: false,
                  },
                  date: {
                    text: "",
                    status: false,
                  },
                  fromDate: {
                    text: "",
                    status: false,
                  },
                  toDate: {
                    text: "",
                    status: false,
                  },
                  delivery: {
                    text: "",
                    status: false,
                  },
                  partyDesignNo: {
                    text: "",
                    status: false,
                  },
                });
              }}
            >
              <div
                className={`relative flex flex-col min-w-0 break-words shadow-xl rounded-2xl bg-clip-border ${
                  type === "lump"
                    ? "bg-gradient-to-r from-yellow-100 to-orange-200"
                    : "bg-white"
                } `}
              >
                <div className="flex-auto p-4">
                  <p className="mb-0 font-sans text-xs font-semibold leading-normal uppercase">
                    Lump Matching Cards
                  </p>
                  <div className="flex flex-row -mx-3">
                    <div className="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <h5 className="mb-2 font-bold">
                          <CountUp end={lumpCount} duration={0.5} />
                        </h5>
                      </div>
                    </div>
                    <div className="px-3 text-right basis-1/3">
                      <div className="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i className="ni leading-none fas fa-chart-area text-xl relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full px-6 pt-6 mx-auto">
          <div className="rounded-xl bg-white">
            <div className="mb-6 py-4 break-words border-0 border-transparent border-solid shadow-xl rounded-2xl bg-clip-border">
              <div className="px-3">
                {type === "all" || userData?.role === "designer" ? null : (
                  <button
                    className="px-5 py-2 text-base leading-normal text-center text-white capitalize transition-all ease-in rounded-lg shadow-md bg-themeColor bg-150 hover:shadow-xs hover:-translate-y-px"
                    type="button"
                    onClick={() => {
                      navigate(`/matchingcards?type=${type}`);
                    }}
                  >
                    <i className="fas fa-folder-plus"></i>
                    <span className="ml-2">Add {type} Card</span>
                  </button>
                )}
              </div>
              <div className="flex-auto px-0 pt-0 pb-2">
                <div className="p-0 overflow-x-auto capitalize">
                  <DataTable
                    title={type + " Cards"}
                    className="touch-auto"
                    columns={columns}
                    subHeaderComponent={subHeaderComponentMemo}
                    paginationResetDefaultPage={resetPaginationToggle}
                    progressPending={isFetching || isCardFetching}
                    progressComponent={<DataTableLoader />}
                    data={cardData}
                    fixedHeader={true}
                    defaultSortAsc={false}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    striped={true}
                    responsive={true}
                    highlightOnHover
                    persistTableHead
                    subHeader
                    customStyles={customStyles}
                    onRowClicked={(e: any) => {
                      if (!e?._id) return;
                      setSelectedRow(e);
                      setIsModalOpen(true);
                    }}
                    paginationPerPage={20}
                    footer={footer}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen ? (
        <TableAction
          header={selectedRow?.invoiceNo + "-" + selectedRow?.cardNo}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          selectedRow={selectedRow}
          isCopy={true}
          isPreview={true}
          // copyTrigger={copyTrigger}
          openInvoicePreview={openInvoicePreview}
          handleDelete={handleDelete}
          route={"matchingcards"}
        />
      ) : null}
      {/* Pdf Preview */}
      {showMatchingPreview && singleCard?.type === "dress" && (
        <MatchingCardPreview
          showMatchingPreview={showMatchingPreview}
          setShowMatchingPreview={setShowMatchingPreview}
          singleCard={singleCard}
          id={singleCard?._id}
          pageName="Matching card"
        />
      )}
      {showMatchingPreview && singleCard?.type === "saree" && (
        <SareeMatchingCardPreview
          showMatchingPreview={showMatchingPreview}
          setShowMatchingPreview={setShowMatchingPreview}
          singleCard={singleCard}
          id={singleCard?._id}
          pageName="Matching card"
        />
      )}
      {showMatchingPreview && singleCard?.type === "lump" && (
        <LumpMatchingCardPreview
          showMatchingPreview={showMatchingPreview}
          setShowMatchingPreview={setShowMatchingPreview}
          singleCard={singleCard}
          id={singleCard?._id}
          pageName="Matching card"
        />
      )}
      {isExportData && (
        <MatchingCardDataPdf
          isExportData={isExportData}
          setIsExportData={setIsExportData}
          matchingCard={cardData}
          allCount={allCount}
          isSearching={isSearching}
          totalTPPMCount={totalTPPMCount}
          search={search}
          type={type}
          pageName="Matching card"
          isPartyPdf={isPartyPdf}
        />
      )}
      {isExportSareeData && (
        <SareeMatchingCardPdf
          isExportSareeData={isExportSareeData}
          setIsExportSareeData={setIsExportSareeData}
          matchingCard={cardData}
          allCount={allCount}
          isSearching={isSearching}
          totalTPPMCount={totalTPPMCount}
          search={search}
          type={type}
          pageName="Matching card"
        />
      )}
    </>
  );
}
